<template>
    <div class="content-main">
        <!--title区-->
        <div>
            <div class="content-header">
                <h2>卡组列表</h2>
            </div>
            <div>
                <el-divider></el-divider>
            </div>
        </div>
        <div class="content-title">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
                <el-breadcrumb-item >卡组列表</el-breadcrumb-item>
            </el-breadcrumb>
            <!--<div>
                如果提交的套牌配方内容不完整（如卡牌数量不足），则无法发布。 如果你中了奖，而卡组配方没有发布，可能有问题，所以请联系Card Fight!! Vanguard Card Game 秘书处。
            </div>-->
        </div>

        <!--检索区域-->
        <div class="deck-search">
            <el-form label-width="150px" v-model="search">
                <el-form-item label="国家/集团">
                    <el-select v-model="search.label">
                        <el-option
                                v-for="item in nationOptions"
                                :key="'nation' + item.val"
                                :label="item.name"
                                :value="item.val"
                        ></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="卡片名称/编号">
                    <el-input style="width: 202px" v-model="search.card_name_or_number"></el-input>
                </el-form-item>

                <el-form-item label="赛制">
                    <el-radio-group v-model="search.rule_type">
                        <el-radio label="1" >标准赛</el-radio>
                        <el-radio label="2" >V标准赛</el-radio>
                        <el-radio label="3" >高阶标准赛</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item >
                    <el-button type="success" style="width: 200px" @click="handleSearch">搜索</el-button>
                    <el-button  style="width: 200px" @click="handleReset">重置</el-button>
                </el-form-item>
            </el-form>
        </div>

        <!--列表区-->
        <div class="content-list">
            <div>
                <el-divider>推荐卡组列表</el-divider>
            </div>
            <div class="list-area">
                <div class="list-item">
                    <div v-for="(v,k) in formatData" :key="k">
                        <el-row>
                            <el-col :span="4"><div class="list-title-date">{{$moment(v.created_at).format("YYYY/MM/DD") }}</div></el-col>
                            <el-col :span="4" v-if="v.type ==1">
                                <div class="list-title-tab">活动上位</div>
                            </el-col>
                            <el-col :span="4" v-if="v.type ==2">
                                <div class="list-title-tab" style="background-color: #da3175">官方推荐</div>
                            </el-col>
                            <el-col :span="4" v-if="v.type ==3">
                                <div class="list-title-tab" style="background-color: #d3c021">动画再现</div>
                            </el-col>
                            <el-col :span="15" :offset="1">
                                <div>
                                    <router-link :to="{path: '/index/deckDetail',query:{deck_id:v.deck_id}}">
                                        <div v-if="v.type==1">{{v.tournament.name+'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+'第'+v.rank+'名'}}</div>
                                        <div v-if="v.type==2 || v.type == 3">{{v.site_deck_content.title}}</div>
                                    </router-link>
                                </div>
                                <div>{{v.deck.label}}</div>
                            </el-col>
                        </el-row>
                        <el-divider></el-divider>
                    </div>
                </div>
            </div>
        </div>
        <!--分页区-->
        <div style="padding:20px 0;width: 60%;margin: 0 auto">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="pageSizes"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                pageSize:this.$webConfig.pages.pageSize,
                pageSizes:this.$webConfig.pages.pageSizes,
                currentPage:1,
                total:1000,
                dataList:[],
                search:{
                    rule_type:0,
                    card_name_or_number:"",
                    label:""
                },
                nationOptions:[
                    {name:"王冠圣域",val:"王冠圣域"},
                    {name:"龙族帝国",val:"龙族帝国"},
                    {name:"布兰特之门",val:"布兰特之门"},
                    {name:"暗邦",val:"暗邦"},
                    {name:"基元",val:"基元"},
                    {name:"圣律诗院",val:"圣律诗院"},
                ],
            }
        },
        methods: {
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                this.currentPage = val;
                this.getList();
            },
            getList(){
                this.$axios.post("/site/siteDeck/indexList",this.search)
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.dataList = res.data.data.list;
                            this.total = res.data.data.total;
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
            },
            handleSearch() {
                this.getList();
            },
            handleReset(){
                this.search = {};
            }

        },
        computed: {
            formatData() {
                let newData = [];
                console.log(this.dataList)
                this.dataList.forEach((v)=>{

                    newData.push(v);
                });
                return newData;
            }
        },
        created() {
            this.getList();
        }
    }
</script>

<style scoped>
    .content-main{
        width: 100%;
        margin: 0 auto;
        background-color: white;
        padding: 20px 0;
    }
    .content-header{
        text-align: center;
    }
    .content-title{
        width: 60%;
        margin: 0 auto;
    }
    .content-title div:last-child{
        /*border: 3px solid #f56f6f;*/
        padding: 15px;
        text-align: center;
        letter-spacing: 2px;
        border-radius: 10px;
        margin: 40px 0;
        line-height: 30px;
    }
    .content-list{
        font-size: 24px;
    }
    .list-area {
        width: 60%;
        margin: 0 auto;
        margin-top: 50px;
    }
    .list-item > div{
        width: 100%;
        font-size: 18px;
        /*border-bottom: 2px solid #eeeeee;*/
        margin: 20px 0;
    }
    .list-item a:visited{
        color: #0000ee;
    }

    .list-title-tab{
        background-color: #4cb748;
        color: white;
        text-align: center;
        line-height: 30px;
        width: 120px;
    }
    .list-title-date{
        line-height: 30px;
    }
    .deck-search{
        width: 60%;
        margin: 0 auto;
        border: 2px solid #dcdfe6;

        border-radius: 10px;
        padding: 20px 0px;
    }

</style>
